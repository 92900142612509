import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import awsconfig from "./aws-exports.js";

Amplify.configure({
  aws_project_region: "eu-west-2",
  aws_cognito_region: "eu-west-2", // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: "eu-west-2_GmOnSW1Cs", // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: "408b9lernum113ncb6lm9tki2u", // (o
  oauth: {
    domain: "dev-ris-app.auth.eu-west-2.amazoncognito.com",
    // scope: [
    //   "phone",
    //   "email",
    //   "profile",
    //   "openid",
    //   "aws.cognito.signin.user.admin",
    // ],
    redirectSignIn: "http://localhost:3000",
    redirectSignOut: "http://localhost:3000",
    clientId: "408b9lernum113ncb6lm9tki2u",
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <p>User: {user ? JSON.stringify(user.attributes) : "None"}</p>
          {user ? (
            <button onClick={() => Auth.signOut()}>Sign Out</button>
          ) : (
            <button onClick={() => Auth.federatedSignIn()}>
              Federated Sign In
            </button>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
